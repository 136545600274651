<template>
    <b-container fluid>
      <b-row>
        <!-- <b-col cols="12">
          <div class="iq-card mb-0" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0" id="menu">
            <div id="menu-navi" class="iq-card-body">
              <button type="button" class="btn mr-1 btn-outline-primary" data-action="move-today">Today</button>
              <b-row>
                <b-col cols="6">
                  <div class="feild">
                    <b-form-input
                      type="month"
                      v-model="selectedDate"
                      required
                    ></b-form-input>
                  </div>
                </b-col>
              </b-row>
              </div>
            </div>
        </b-col> -->
        <div class="add-price-btn bg-primary" @click="priceInit()">
          <i class="ri-add-line"></i>
        </div>
        <div class="edit-price">
          <b-modal id="edit-price" :ok-title="$t('save')" :cancel-title="$t('cancel')" :hide-footer="editableRoom" :title="$t('room-price')" @ok="updatePrice">
            <b-form class="gap-5">
              <b-row>
                <b-col sm="12">
                  <div class="feild">
                    <label for="">{{ $t('room-price') }}</label>
                      <b-form-input
                        type="text"
                        v-model="editPayload.price"
                        required
                      ></b-form-input>
                    </div>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
        <div class="add-price">
          <b-modal id="price-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('room-price')" @ok="submitPrice">
            <b-form class="gap-5">
              <b-row>
                <b-col sm="12">
                  <div class="feild">
                      <label for="">{{ $t('room-price') }}</label>
                      <b-form-input
                        type="text"
                        v-model="payload.price"
                        required
                      ></b-form-input>
                    </div>
                </b-col>
                <b-col sm="12">
                  <div class="feild">
                      <label for="">{{ $t('start-data') }}</label>
                      <b-form-input
                        type="date"
                        :min="new Date().toISOString().slice(0,10)"
                        v-model="payload.start_date"
                        required
                      ></b-form-input>
                    </div>
                </b-col>
                <b-col sm="12">
                  <div class="feild">
                      <label for="">{{ $t('end-data') }}</label>
                      <b-form-input
                        type="date"
                        :min="new Date().toISOString().slice(0,10)"
                        v-model="payload.end_date"
                        required
                      ></b-form-input>
                    </div>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
        <b-col cols="12">
          <FullCalendar ref="calendar"  @datesSet="nextBtnEvent"  :options='calendarOptions'>
            <template v-slot:eventContent='arg'>
              <div class="event-container text-center">
                <i style="font-size: 1rem;" class="text-dark font-weight-bold" @click="editInit(arg)">{{ arg.event.title }}</i>
              </div>
            </template>
          </FullCalendar>
        </b-col>
      </b-row>
    </b-container>
  </template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
export default {
  name: 'App',
  components: {
    FullCalendar
  },
  data () {
    // const today = new Date().toISOString().split('T')[0]
    return {
      startData: new Date(),
      selectedDateInfo: {},
      selectedDate: '',
      editableRoom: true,
      payload: {
        price: '',
        start_date: '',
        end_date: ''
      },
      editPayload: {
        price: ''
      },
      calendarOptions: {
        height: 650,
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        eventClick: this.editInit,
        datesSet: this.nextBtnEvent,
        events: [],
        eventColor: '#f8f7fd',
        showNonCurrentDates: false,
        validRange: {
          start: new Date() // Start from today's date
        }
      }
    }
  },
  watch: {

  },
  methods: {
    showDateInfo (info) {},
    priceInit () {
      this.$bvModal.show('price-modal')
    },
    editInit (clickInfo) {
      this.$bvModal.show('edit-price')
      if (clickInfo.event._def.extendedProps.price.date > new Date().toISOString().slice(0, 10)) {
        this.editableRoom = false
      } else {
        this.editableRoom = true
      }
      this.editPayload.id = clickInfo.event._def.extendedProps.price.id
      this.editPayload.price = clickInfo.event._def.extendedProps.price.price
      // this.showRoom =
      return clickInfo
    },
    submitPrice () {
      this.sortRoomPrice({
        room_id: this.$route.params.id,
        ...this.payload
      }).then(() => {
        this.getPriceData(this.selectedDateInfo)
      })
    },
    updatePrice () {
      this.updateRoomPrice(this.editPayload).then(() => {
        this.getPriceData(this.selectedDateInfo)
      })
    },
    getPriceData (query) {
      this.getRoomPriceList({
        id: this.$route.params.id,
        query
      }).then(() => {
        var eventData = []
        this.roomPrice.data.map(el => {
          eventData.push({
            title: el.price,
            start: el.date,
            id: el.id,
            price: el
          })
        })
        this.calendarOptions.events = eventData
      })
    },
    nextBtnEvent (info) {
      this.selectedDateInfo = {
        from: info.startStr.slice(0, 10),
        to: info.endStr.slice(0, 10)
      }
      this.getPriceData(
        {
          from: info.startStr.slice(0, 10),
          to: info.endStr.slice(0, 10)
        }
      )
    }
  },

  mounted () {
    this.getRooms()
  }
}
</script>
<style>
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner{
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.fc-daygrid-day-top{
  position: absolute;
  top: 0;
  right: 5px;
}

.fc-daygrid-day-frame:hover {
  background-color: #ccc;
}

.fc-daygrid-day-frame:hover  .event-container {
  background-color: #ccc !important;
  border: none;
}

.fc-daygrid-day-frame:active .event-container {
  background-color: transparent !important;
  border: none;
}

.fc-daygrid-day-frame a   {
  border: none;
}
.add-price-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
}

</style>
